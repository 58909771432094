<template>
    <Loader :show="submitted"></Loader>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form id="form_search_sendgrid" class="needs-validation" @submit.prevent="getEntidades">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="mb-3">

                                        <label for="select_field_for_search">Campo de búsqueda</label>
                                        <select id="select_field_for_search" v-model="selectedField" class="form-select">
                                            <option value="correo_receptor">Correo receptor</option>
                                            <option value="subject">Asunto</option>
                                        </select>

                                    </div>
                                </div>
                                <div v-if="selectedField == 'correo_receptor'" class="col-md-3">
                                    <div class="mb-3">
                                        <label for="input_correo_receptor">{{ selectedFieldFormatted }}</label>
                                        <input id="input_correo_receptor" placeholder="valor" v-model="correoReceptorValue"
                                            type="text" class="form-control" :class="{
                                                'is-invalid': submitted && v$.correoReceptorValue.$error,
                                            }" />
                                        <div v-if="submitted && v$.correoReceptorValue.$error" class="invalid-feedback">
                                            <span v-if="v$.correoReceptorValue.required.$message">{{
                                                v$.correoReceptorValue.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedField == 'subject'" class="col-md-3">
                                    <div class="mb-3">
                                        <label for="input_subject">{{ selectedFieldFormatted }}</label>
                                        <input id="input_subject" placeholder="valor" v-model="subjectValue" type="text"
                                            class="form-control" :class="{
                                                'is-invalid': submitted && v$.subjectValue.$error,
                                            }" />
                                        <div v-if="submitted && v$.subjectValue.$error" class="invalid-feedback">
                                            <span v-if="v$.subjectValue.required.$message">{{
                                                v$.subjectValue.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-3">

                                        <label for="input_initial_date">Fecha inicio</label>
                                        <datepicker id="input_initial_date" v-model="initialDate" :first-day-of-week="1"
                                            lang="en" confirm class="form-control"></datepicker>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="mb-3">

                                        <label for="input_final_date">Fecha final</label>
                                        <datepicker id="input_final_date" v-model="finalDate" :first-day-of-week="1"
                                            lang="en" confirm class="form-control"></datepicker>

                                    </div>
                                </div>
                            </div>
                            <button id="btn_search_sendgrid_form" class="btn btn-primary" type="submit">Buscar</button>
                        </form>
                    </div>
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
    </Layout>

    <Layout>
        <TableMailComponent :logData="logData" :headers="headers" :pagination="pagination"
            :callback-search="getEntidadesFake" @on-pagination="pagination = $event" />
        <!-- Se envía el objeto params al componente TableMailComponent -->
    </Layout>
</template>
  
<script>

const headers = [
    { index: 'from_email', label: 'Envíado desde', filter: false, visible: true },
    { index: 'to_email', label: 'Al correo', filter: false, visible: true },
    { index: 'status', label: 'Estado', filter: false, visible: true },
    { index: 'open', label: 'Abierto', filter: false, selected: false, visible: true }
];

const pagination = {
    columnName: 'from_email',
    sortOrder: 'ASC',
    rfc: '',
    page: 1,                //currentPage
    listaPageSize: [10, 20, 30, 50, 100, 200],
    pageSize: 30,//page size de la primera vez, tiene que coincidide con 
    actualShow: 0,
    collectionSize: 0, //tamaño del resultado
}
const optionsSelect = {
    correo_receptor: "Correo receptor",
    subject: "Asunto",
};

import { mailService } from '../../helpers/mailService/mail.service';
import TableMailComponent from "@/components/EmailSearchForm/TableMailComponent.vue";
import { helpers, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Datepicker from "vue3-datepicker";
import { ref } from 'vue';
import {authComputed} from "@/state/helpers";
import Loader from '../widgets/loader';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Datepicker,
        TableMailComponent,
        Loader
    },
    data() {
        return {
            submitted: false,
            headers,
            pagination,
            optionsSelect,
            lstEntidades: [],
            selectedField: "correo_receptor", // Campo seleccionado en el select
            correoReceptorValue: "", // Valor del campo de búsqueda
            subjectValue: "",
            initialDate: ref(new Date()), // Primera fecha
            finalDate: ref(new Date()), // Segunda fecha
            logData: {
                type: Object,
            },
            reponseData: {
                type: Object
            }
        };
    },
    validations: {
        //valida solo si el input contiene un valor, si el input es vacío no valida ya que el campo no es requerido
        correoReceptorValue: {
            required: helpers.withMessage('Introduce un correo válido', email)
        },
        subjectValue: {
            required: helpers.withMessage("No se permiten caracteres especiales", subjectValue => {
                return !subjectValue || !/[$%&|<>#]/.test(subjectValue);
            })
        }
    },
    methods: {
        getEntidades() {
            /* let fe = new Date('2023-05-17T15:43:54Z');
            console.log(fe.toLocaleString()); */
            this.submitted = true;
            // stop here if form is invalid
            this.v$.$touch();

            if (this.v$.$invalid) {
                return;
            }

            this.initialDate.setHours(0, 0, 0, 0);
            this.finalDate.setHours(23, 59, 59);

            /**
             * "from_email=\"notificaciones@futuraindustrial.com\" AND last_event_time BETWEEN TIMESTAMP \"2023-05-17T00:00:00Z\" AND TIMESTAMP \"2023-05-17T23:59:59Z\" AND to_email=\"facturaslrangelpeiro@yahoo.com\""
             */
            let additionalQuery = "";
            if(this.selectedField == 'correo_receptor' && this.correoReceptorValue != ''){
                additionalQuery = `AND to_email=\\"${this.correoReceptorValue}\\" `;
            }
            if(this.selectedField == 'subject' && this.subjectValue != ''){
                additionalQuery = `AND subject=\\"${this.subjectValue}\\" `;
            }

            let query = {
                'query': `{"query": "from_email=\\"${this.currentUser.email}\\" AND last_event_time BETWEEN TIMESTAMP \\"${this.initialDate.toISOString()}\\" AND TIMESTAMP \\"${this.finalDate.toISOString()}\\" ${additionalQuery}","limit": 1000}`
            };

            mailService.getMessages(query).then(response => {
                console.log(response.data);
                this.reponseData = response.data.messages;
                let dataToSend = [];
                this.reponseData.forEach(element => {
                    dataToSend.push({
                        from_email: element.from_email,
                        to_email: element.to_email,
                        status: element.status,
                        open: element.opens_count
                    });
                });

                //por defecto muestro los primeros 50 resultados
                dataToSend = dataToSend.slice(0, 30);
                console.log(dataToSend);

                //logData se manda por medio de props
                this.logData = dataToSend;
                this.pagination.actualShow = 30;
                this.pagination.collectionSize = this.reponseData.length;
                this.submitted=false;
            });
        },
        getEntidadesFake(pagination) {
            console.log('desde entidadesFake' + pagination.page);
            console.log(this.reponseData);

            const pageToConsult = pagination.page;
            let startIndex = 0;
            if (pageToConsult == 1) {
                startIndex = 0;
            } else {
                startIndex = ((pageToConsult - 1) * 30) + 1;
            }

            let endIndex = startIndex + 30;
            //tomando en cuenta que por defecto se muestran 10 elementos por pagina los limites son constantes
            let dataToSend = [];
            this.reponseData.forEach(element => {
                dataToSend.push({
                    from_email: element.from_email,
                    to_email: element.to_email,
                    status: element.status,
                    open: element.opens_count
                });
            });
            dataToSend = dataToSend.slice(startIndex, endIndex);
            this.pagination.actualShow = 30;
            this.logData = dataToSend;
        }
    },
    computed: {
        selectedFieldFormatted() {
            const formattedField = this.optionsSelect[this.selectedField];
            return formattedField;
        },
        ...authComputed
    },
};
</script>