<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import SengridForm from "@/components/SengridForm/SengridForm";


export default {
  components: { Layout, PageHeader, SengridForm},
  
  data() {
    return {
      title: "Actividad de sendgrid",
      items: [
        {
          text: "email",
          active: true,
        },
      ],
      defaultField: "correo receptor", // Valor por defecto del select
    };
  },
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    
    <SengridForm :defaultField="defaultField" />
    
  </Layout>
</template>